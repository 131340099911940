@import-normalize;

@import "~factor/dist/fonts.css";
@import "~normalize.css/normalize.css";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";
@import "./scss/mixins";
@import "./scss/btn";
@import "./scss/block";
@import "./scss/text-field";
@import "./scss/select";
@import "./scss/date-picker";
@import "./scss/typography";
@import "./scss/table";

* {
  box-sizing: border-box;
}

html {
  margin: 0;
  font-size: 16px;
}

body {
  margin: 0;
  @extend %text;
  -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6, p, ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.main {
  &._centered {
    display: flex;
    padding: 0;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
  }
}
