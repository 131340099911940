.nav-bar {
  z-index: 30;

  &__content {
    padding: 0 8/16+rem 0 32/16+rem;

    .select {
      width: 190/16+rem;

      &:not(:last-child) {
        margin-right: .5rem;
      }
    }

    .btn-group {
      margin-left: auto;
      margin-right: 56/16+rem;
    }
  }
}

.modal__overflow {

  .nav-bar {
    z-index: 20;
  }
}
