.btn-close {
  position: relative;
  margin: 0;
  width: 14/16+rem;
  height: 14/16+rem;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  &:hover,
  &:focus {
    outline: none;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 20/16+rem;
    height: 2/16+rem;
    border-radius: 1/16+rem;
    background: $gray;
    transition: background .25s;
  }

  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }
}

.btn-round,
.btn-square {
  &._icon-right & {

    &__prefix {
      left: auto;
      right: 10/16+rem;
    }
  }
}
